<template>
  <div class="bb-onboarding">
    <div class="bg-cercles bb-onboarding__container">

      <OnboardingStepFinish />

    </div>
  </div>
</template>
<script>
import OnboardingStepFinish from "./components/OnboardingStepFinish";

export default {
  name: "OnboardingStep2",
  components: {
    OnboardingStepFinish,
  },
};
</script>
