<template>
  <div class="bb-onboarding">
    <div class="bg-cercles bb-onboarding__container">

      <OnboardingStepNameBirthdate />

    </div>
  </div>
</template>

<script>
import OnboardingStepNameBirthdate from "./components/OnboardingStepNameBirthdate";

export default {
  name: "OnboardingStep1",
  components: {
    OnboardingStepNameBirthdate,
  },
}
</script>
