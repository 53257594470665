<template>
  <div class="bb-onboarding">
    <div class="bg-cercles bb-onboarding__container">

      <OnboardingStepEcommerceBirthdateContent />

    </div>
  </div>
</template>

<script>
import OnboardingStepEcommerceBirthdateContent from "./components/OnboardingStepEcommerceBirthdateContent";

export default {
  name: "OnboardingStepEcommerceBirthdate",
  components: {
    OnboardingStepEcommerceBirthdateContent,
  },
}
</script>
